import { render, staticRenderFns } from "./Step7.vue?vue&type=template&id=b2dd6aaa&scoped=true&"
import script from "./Step7.vue?vue&type=script&lang=js&"
export * from "./Step7.vue?vue&type=script&lang=js&"
import style0 from "./Step7.vue?vue&type=style&index=0&id=b2dd6aaa&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2dd6aaa",
  null
  
)

export default component.exports