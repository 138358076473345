<template>
    <div>
        <ul class="text-margin">
            <li class="text">{{ $t('faq.tivimate.step7.selectTv') }}</li>
            <li class="text">{{ $t('faq.tivimate.step7.enterYourTv') }}</li>
        </ul>
        <div class="screenshot">
            <img src="../assets/screen-5.png" alt="" class="step-image">
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style scoped>
@import "../../../components/styles/guides-default-styles.css";
</style>
